// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---node-modules-gatsby-theme-firebase-src-pages-login-tsx": () => import("../node_modules/gatsby-theme-firebase/src/pages/login.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-firebase-src-pages-login-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-application-tsx": () => import("../src/pages/application.tsx" /* webpackChunkName: "component---src-pages-application-tsx" */),
  "component---src-pages-firestore-tsx": () => import("../src/pages/firestore.tsx" /* webpackChunkName: "component---src-pages-firestore-tsx" */),
  "component---src-pages-profile-tsx": () => import("../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-protected-tsx": () => import("../src/pages/protected.tsx" /* webpackChunkName: "component---src-pages-protected-tsx" */),
  "component---src-pages-sdgs-tsx": () => import("../src/pages/sdgs.tsx" /* webpackChunkName: "component---src-pages-sdgs-tsx" */),
  "component---src-pages-social-logins-tsx": () => import("../src/pages/social-logins.tsx" /* webpackChunkName: "component---src-pages-social-logins-tsx" */)
}

